//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'QuoteSingle2',
  props: {
    id: { type: String, default: '' },
    url: { type: String, default: '' },
    quote: { type: String, default: '' },
    imgUrl: { type: String, default: '' },
    imgAlt: { type: String, default: '' },
    name: { type: String, default: '' },
    subtitle: { type: String, default: '' },
  },
};
